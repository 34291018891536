@tailwind base;
@tailwind components;
@tailwind utilities;

.font-outfit-semilight {
  font-family: "Outfit", sans-serif !important;
  font-weight: 300;
}

.font-outfit-light {
  font-family: "Outfit", sans-serif !important;
  font-weight: 400;
}

.font-outfit-medium {
  font-family: "Outfit", sans-serif !important;
  font-weight: 500;
}

.font-outfit-heavy {
  font-family: "Outfit", sans-serif !important;
  font-weight: 600;
}

.video-slides {
  transform: scale(0.8);
  padding: 40px 0;
  transition: all 1s ease-in-out;
}

.slick-center {
  transform: scale(1.3);
  transition: all 1s ease-in-out;
}
